import * as React from "react"
import Page from "../components/page.js"
import {Link} from "gatsby"
import GetStarted from "../components/getStarted.js"

import fields from "../video/fields.mp4"
import { StaticImage } from "gatsby-plugin-image"

import '../components/css/index.scss'

const HomePage = () => {
  return (
    <>
      <Page>
        <section className="landing-container section">
          <video playsInline autoPlay muted loop preload='auto'>
            <source src={fields} type="video/mp4" />
          </video>
          <div className="fancy-line">
          </div>
          <div className="landing-content-wrapper content-wrapper">
            <div className="landing-content">
              <div className="motto">
                <div className="static-motto">
                  <h1>Better AI.</h1>
                </div>
                <div className="dynamic-motto">
                  <span><h1>Better Farming.</h1></span>
                  <span><h1>Higher Yields.</h1></span>
                  <span><h1>Greater Consistency.</h1></span>
                  <span><h1>Superior Automation.</h1></span>
                  <span><h1>Better Crop Steering.</h1></span>
                </div>
              </div>
              <div className="spiel">
                <h3>Better AI leads to more performant growing. Performant growing leads to higher yields and greater quality control. Get higher returns on your growing investments with better AI.</h3>
              </div>
              <div className="action">
                <a href="https://app.instaleaf-ag.com/signup">
                  <button className="get-started">
                    <h6>Get Started</h6>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                    </svg>
                  </button>
                </a>
                <button><Link to="/contact"><h6>Contact Sales</h6></Link></button>
              </div>
            </div>
          </div>
        </section>

        <section className="what-container section">
          <div className="what-content-wrapper content-wrapper">
            <h5>We provide</h5>
            <h3>A fully unified suite of grow technology for complete agriculture management</h3>
            <div className="what-content">
              <h4>
                We build technology that helps growers and farm owners connect to their crops.
                Our mission is to power the smart farms of the future by boosting crop yields for farms and
                increasing ROI while ensuring sustainability. We help growers gain predictive
                insight into their crops through <Link>data gathering, monitoring, alerting</Link> and
                <Link> machine learning intelligence</Link> to predict when things aren't going right and
                fix them with <Link>automation</Link>.
              </h4>
              <h4>
                We also help farms <Link>automate repetitive growing tasks</Link>, <Link>ensure safety requirements</Link>,
                <Link> save energy</Link>, <Link>manage water usage efficiently</Link>, <Link>maintain healthy soils</Link>,
                <Link> greatly reduce greenhouse gas emissions</Link>, <Link> optimize crop cycles</Link>, <Link> manage grower operations</Link> and much more.
              </h4>
            </div>
          </div>
        </section>

        <section className="design-container section">
          <div className="design-content-wrapper content-wrapper">
            <div className="design-content">
              <h5>Designed for Growers</h5>
              <h3>The industry’s most powerful and easy-to-use analytics platform</h3>
              <h4>
                  We curate the right analytics and machine learning models so your
                  team has access to essential insights and predictions on any grow.
                  With data to back up your grow, we help you spend more time doing
                  and less time thinking.
              </h4>
              <button>
                <h6>Explore Data Platform</h6>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                </svg>
              </button>
              <div className="more-info">
                <div className="iot info">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-hdd-stack" viewBox="0 0 16 16">
                    <path d="M14 10a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h12zM2 9a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1a2 2 0 0 0-2-2H2z"/>
                    <path d="M5 11.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM14 3a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"/>
                    <path d="M5 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                  </svg>
                  <h5>Devices for all grows</h5>
                  <h6>We design and develop sensors and automation tools for all types of grows. If you need a new tool to help bolster your setup we are here to help.</h6>
                  <button>
                    <Link to=""><h6>View IoT Devices</h6></Link>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                    </svg>
                  </button>
                  <button>
                    <Link to="/contact"><h6>Contact Sales</h6></Link>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                    </svg>
                  </button>
                </div>
                <div className="integrations info">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-intersect" viewBox="0 0 16 16">
                    <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm5 10v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-2v5a2 2 0 0 1-2 2H5zm6-8V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2V6a2 2 0 0 1 2-2h5z"/>
                  </svg>
                  <h5>Data from any source</h5>
                  <h6>Our platform is capable of handling data from any and all sources. Whether you manually gather data or already have tooling in place, our platform can ingest data at any granularity.</h6>
                </div>
              </div>
            </div>
            <div className="design-content img-content">
              <StaticImage
                src="../images/platform.png"
                alt="Platform"
                className="img"
              />
              <StaticImage
                src="../images/platform_data.png"
                alt="Platform Data"
                className="img"
              />
            </div>
          </div>
        </section>

        <section className="why-container section">
          <div className="why-content-wrapper content-wrapper">
            <h5>Why InstaLeaf</h5>
            <h3>A technology-first approach to farming and cultivation.</h3>
            <div className="why-content">
              <div className="grid-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-bar-chart-steps" viewBox="0 0 16 16">
                  <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0zM2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1z"/>
                </svg>
                <h6 className="title">AI and data driven core</h6>
                <h6 className="details">
                  We employ machine learning models on billions of data points to identify grow patterns and deliver value to growers. Our AI gives farmers deeper insight into their enviroment and greater confidence in their grow backed by hard data.
                </h6>
              </div>
              <div className="grid-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-shield-lock" viewBox="0 0 16 16">
                  <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
                  <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z"/>
                </svg>
                <h6 className="title">Scalable and secure systems</h6>
                <h6 className="details">
                  Security is at the forefront of all our products. Customers can operate in confidence knowing that their grows are secure and backed by highly scalable and redundant systems.
                </h6>
              </div>
              <div className="grid-item">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-bullseye" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10zm0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
                  <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"/>
                  <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                </svg>
                <h6 className="title">Lightweight IoT Ecosystem</h6>
                <h6 className="details">
                  From soil moisture sensors to automated valves, we provide a simple integrated suite of devices for growers to interface with their grows. We enable growers to focus on the bigger picture with full trust our system is covering the details.
                </h6>
              </div>
              <div className="grid-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-activity" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z"/>
                </svg>
                <h6 className="title">Fast-improving platform</h6>
                <h6 className="details">
                  We constantly release new features, grow science, and improvements to our platform to ensure you have the leading-edge in technology to support your grow.
                </h6>
              </div>
            </div>
          </div>
        </section>

        <section className="use-container section">
          <div className="use-content-wrapper content-wrapper">
            <div className="use-content">
              <h3>Use Cases</h3>
              <h4>InstaLeaf's AI platform & technology are versitile and can be used in nearly every sector of agriculture.</h4>
              <div className="uses">
                <div className="use">
                  <StaticImage
                    src="../images/outdoor.png"
                    alt="Outdoor farming"
                    className="use-img"
                  />
                  <h5>Outdoor Farming</h5>
                </div>

                <div className="use">
                  <StaticImage
                    src="../images/vert.png"
                    alt="Indoor farming"
                    className="use-img"
                  />
                  <h5>Vertical Farming</h5>
                </div>

                <div className="use">
                  <StaticImage
                    src="../images/greenhouse.png"
                    alt="Greenhouse farming"
                    className="use-img"
                  />
                  <h5>Greenhouses</h5>
                </div>

                <div className="use">
                  <StaticImage
                    src="../images/cannibis.png"
                    alt="Cannibis farming"
                    className="use-img"
                  />
                  <h5>Cannabis</h5>
                </div>

                <div className="use">
                  <StaticImage
                    src="../images/grape.png"
                    alt="Wine farming"
                    className="use-img"
                  />
                  <h5>Wineries</h5>
                </div>

                <div className="use">
                  <StaticImage
                    src="../images/strawberry.png"
                    alt="Strawberry farming"
                    className="use-img"
                  />
                  <h5>Seasonal Crops</h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        <GetStarted />
      </Page>
    </>
  )
}

export default HomePage
